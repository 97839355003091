import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../Footer';
import { Header } from '../Header';
import * as C from './styles';

export const Unexpected = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const errorIfNotIsHome = 'Clique aqui para retornar à Home';
  const errorIfIsHome = 'Clique Aqui para fazer uma nova pesquisa';

  return (
    <>
      <Header />
      <C.Container>
        <h1>Ops! Ocorreu algo inesperado.</h1>
        <h3>
          Desculpe-nos pelo transtorno. Estamos trabalhando para melhorar sua
          navegação.
        </h3>
        {location.pathname === '/' && (
          <C.Button onClick={() => window.location.reload()}>
            {errorIfIsHome}
          </C.Button>
        )}
        {location.pathname !== '/' && (
          <C.Button onClick={() => navigate('/')}> {errorIfNotIsHome}</C.Button>
        )}
        {location.pathname !== '/' && <Footer />}
      </C.Container>
    </>
  );
};
